<template>
  <section class="dtc-main-section">
    <div class="dtc-save-print-btn d-print-none">
      <b-button variant="success" @click="save" class="d-print-none mr-2">
        <i class="fas fa-save mr-1"></i>儲存變更
      </b-button>
      <b-button variant="danger" @click="printDtxPage" class="d-print-none" hidden>
        <i class="fas fa-print mr-1"></i>列印
      </b-button>
    </div>
    <h3>展延服務申請書</h3>
    <div class="dtc-words">
      公費生
      <input v-model="map.StudentName" /> 於
      <input v-model="map.FinishYear" />年
      <input v-model="map.FinishMonth" /> 月畢業於
      <input v-model="map.SchoolName" />大學
      <input v-model="map.MajorName" />系學生擬申請報考
      <input v-model="map.InstituteName" />研究所，擬申請展延服務
      <input v-model="map.ExtensionYear" />年，
      並於研究所畢業後再行公費生所應服務事項，鑑請核示此據
    </div>
    <footer class="dtc-f1">
      <div>
        <div>立書人(簽名蓋章)：</div>
        <input v-model="map.ApplyName" />
      </div>
      <div>
        <div>身分證字號：</div>
        <input v-model="map.ApplyId" />
      </div>
      <div>
        <div>住址：</div>
        <input v-model="map.ApplyAddrs" />
      </div>
      <div>
        <div>住宅電話：</div>
        <input v-model="map.ApplyTel" />
      </div>
      <div>
        <div>行動電話：</div>
        <input v-model="map.ApplyMobile" />
      </div>
      <div>
        <div>保證人(簽名蓋章)：</div>
        <input v-model="map.Warrantor" />
      </div>
    </footer>

    <div class="dtc-f2">
      中華民國
      <input v-model="year" />年
      <input v-model="month" />月
      <input v-model="day" />日
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const urlPost = "applyform4/Post";
const urlPut = "applyform4/Put";
const urlGet = "applyform4/Get?Identifier=";
const zero = "T00:00:00";

export default {
  name: "totalSpend",
  data() {
    return {
      countrys: [],
      map: {},
      method: "post",
      year: "",
      month: "",
      day: ""
    };
  },

  methods: {
    printDtxPage() {
      window.print();
    },
    async save() {
      if (this.year) {
        const year = Number(this.year) + 1911;
        this.month = this.month.length == 1 ? "0" + this.month : this.month;
        this.day = this.day.length == 1 ? "0" + this.day : this.day;
        this.map.CreateDate = year + "-" + this.month + "-" + this.day + zero;
      }
      try {
        if (this.method == "post") {
          await window.axios.post(urlPost, this.map);
          //await window.axios.put(urlPut, this.map);
        } else {
          await window.axios.put(urlPut, this.map);
        }
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {
        alert("save fail " + e);
      }
    }
  },
  async mounted() {
    try {
      const {
        Identifier = "H102463549",
        StudentName = "ABC",
        SchoolName = "臺北醫學大學",
        method = "post"
      } = queryString.parse(location.href.split("?")[1]);
      this.method = method;
      this.map = await window.axios.get(urlGet + Identifier);
      const [year, month, day] = this.map.CreateDate
        ? this.map.CreateDate.split("T")[0].split("-")
        : {};

      if (year) {
        this.year = Number(year) - 1911;
        this.month = month;
        this.day = day;
      }
      //alert(this.year);
    } catch (e) {
    } finally {
    }
  },
  beforeMount() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: auto;
  display: grid;
  --dtc-border-color: var(--dark);
  input {
    max-height: 30px;
    display: inline-block;
    margin: 0 12px;
    border: none;
    border-bottom: 1px solid var(--dtc-border-color);
    outline: none;
  }
}
.dtc-words {
  line-height: 40px;
  input {
    text-align: center;
  }
}
footer {
  display: grid;
  margin-top: 3rem;
}
footer {
  > div {
    margin: 8px 0;
    display: grid;
    input {
      min-width: 600px;
    }
    grid-template-columns: 180px max-content;
  }
}
.dtc-f2 {
  margin-top: 3rem;
  input {
    text-align: center;
  }
}
h3 {
  margin: auto;
  margin-bottom: 18px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    //size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark);
  }
}
</style>
